"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTopArtistsLazyQuery = exports.useTopArtistsQuery = exports.TopArtistsDocument = exports.useTopAlbumsLazyQuery = exports.useTopAlbumsQuery = exports.TopAlbumsDocument = exports.useTopTracksLazyQuery = exports.useTopTracksQuery = exports.TopTracksDocument = void 0;
var client_1 = require("@apollo/client");
var Apollo = __importStar(require("@apollo/client"));
var defaultOptions = {};
exports.TopTracksDocument = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query TopTracks {\n  user(name: \"cmarcksthespot\") {\n    topTracks {\n      name\n      url\n      artist {\n        name\n      }\n      album {\n        image {\n          size\n          url\n        }\n      }\n    }\n  }\n}\n    "], ["\n    query TopTracks {\n  user(name: \"cmarcksthespot\") {\n    topTracks {\n      name\n      url\n      artist {\n        name\n      }\n      album {\n        image {\n          size\n          url\n        }\n      }\n    }\n  }\n}\n    "])));
function useTopTracksQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.TopTracksDocument, options);
}
exports.useTopTracksQuery = useTopTracksQuery;
function useTopTracksLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.TopTracksDocument, options);
}
exports.useTopTracksLazyQuery = useTopTracksLazyQuery;
exports.TopAlbumsDocument = (0, client_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    query TopAlbums {\n  user(name: \"cmarcksthespot\") {\n    topAlbums {\n      name\n      url\n      image {\n        url\n        size\n      }\n      artist {\n        name\n      }\n    }\n  }\n}\n    "], ["\n    query TopAlbums {\n  user(name: \"cmarcksthespot\") {\n    topAlbums {\n      name\n      url\n      image {\n        url\n        size\n      }\n      artist {\n        name\n      }\n    }\n  }\n}\n    "])));
function useTopAlbumsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.TopAlbumsDocument, options);
}
exports.useTopAlbumsQuery = useTopAlbumsQuery;
function useTopAlbumsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.TopAlbumsDocument, options);
}
exports.useTopAlbumsLazyQuery = useTopAlbumsLazyQuery;
exports.TopArtistsDocument = (0, client_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query TopArtists {\n  user(name: \"cmarcksthespot\") {\n    topArtists {\n      name\n      url\n      image {\n        url\n        size\n      }\n    }\n  }\n}\n    "], ["\n    query TopArtists {\n  user(name: \"cmarcksthespot\") {\n    topArtists {\n      name\n      url\n      image {\n        url\n        size\n      }\n    }\n  }\n}\n    "])));
function useTopArtistsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(exports.TopArtistsDocument, options);
}
exports.useTopArtistsQuery = useTopArtistsQuery;
function useTopArtistsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(exports.TopArtistsDocument, options);
}
exports.useTopArtistsLazyQuery = useTopArtistsLazyQuery;
var templateObject_1, templateObject_2, templateObject_3;
